<template>
  <CRow>
    <CCol col="12" sm="12">
      <CRow>
        <CCol col="12" sm="8">
          <CCard>
            <CCardHeader>
              {{ $t('profile') }}
            </CCardHeader>
            <CCardBody>
              <CInput
                :label="$t('name')"
                :placeholder="$t('name')"
                :value.sync="editProfileData.name"
                plaintext
              />
              <CInput
                :label="$t('email')"
                type="email"
                :placeholder="$t('email')"
                :value.sync="editProfileData.email"
              />
              <CButton color="primary" class="px-5" @click="updateAdminProfile()">{{ $t('save') }}</CButton>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>
              {{ $t('change_password') }}
            </CCardHeader>
            <CCardBody>
              <CInput
                :label="$t('current_password')"
                type="password"
                :placeholder="$t('current_password')"
                :value.sync="passwordData.current_password"
              />
              <CInput
                :label="$t('new_password')"
                type="password"
                :placeholder="$t('new_password')"
                :description="$t('password_min_msg')"
                :value.sync="passwordData.new_password"
              />
              <CInput
                :label="$t('new_password_confirmation')"
                type="password"
                :placeholder="$t('new_password_confirmation')"
                :value.sync="passwordData.new_password_confirmation"
              />
              <CButton color="primary" class="px-5" @click="updateAdminPassword()">{{ $t('save') }}</CButton>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol col="12" sm="4">
          <CCard>
            <CCardBody>
              <div class="d-flex justify-content-center align-items-center pb-3">
                <div class="c-avatar shadow" style="width: 120px;height: 120px;">
                  <img
                    :src="profileData.avatar"
                    class="c-avatar-img"
                  />
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center pb-3">
                <CButton color="info" size="sm" class="px-4" @click="editAvatar()">{{ $t('change_avatar') }}</CButton>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <p class="h6 font-weight-bold">{{ profileData.name }}</p>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <font class="text-muted">{{ profileData.email }}</font>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <hr style="width: 80%;">
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <p class="h5"></p>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CModal
        :title="$t('change_avatar')"
        size="xl"
        color="info"
        :show.sync="editAvatarModal"
      >
        <CRow>
          <CCol col="6" sm="4" md="4" lg="3" xl="2" v-for="(option, index) in avatarOptions" v-bind:key="'avatar' + index">
            <div :class="['c-avatar m-3', { 'avatarActive': option.active, 'p-1': option.active, 'shadow': option.active }]" style="cursor: pointer;width: 120px;height: 120px;" @click="avatarActive(index)">
              <img
                :src="option.avatar"
                class="c-avatar-img"
              />
            </div>
          </CCol>
        </CRow>
        <CButton slot="footer" color="primary" block @click="updateAdminAvatar()">{{ $t('save') }}</CButton>
      </CModal>

    </CCol>
  </CRow>
</template>

<style>
  .avatarActive {
    border-color: green;
    border-style: solid;
    border-width: .125rem;
  }
</style>

<script>
export default {
  name: 'profile',
  components: {
  },
  data () {
    return {
      avatarOptions: [
        { avatar: 'img/avatars/man.png', active: false },
        { avatar: 'img/avatars/man-1.png', active: false },
        { avatar: 'img/avatars/man-2.png', active: false },
        { avatar: 'img/avatars/man-3.png', active: false },
        { avatar: 'img/avatars/man-4.png', active: false },
        { avatar: 'img/avatars/man-5.png', active: false },
        { avatar: 'img/avatars/woman.png', active: false },
        { avatar: 'img/avatars/woman-1.png', active: false },
        { avatar: 'img/avatars/woman-2.png', active: false },
        { avatar: 'img/avatars/woman-3.png', active: false },
        { avatar: 'img/avatars/woman-4.png', active: false },
        { avatar: 'img/avatars/woman-5.png', active: false }
      ],
      langsOptions: [
        { value: 'tw', label: '繁體中文' },
        { value: 'en', label: 'English' }
      ],
      profileData: { name: '', email: '', locale: this.$store.getters.locale, avatar: 'img/avatars/man.png' },
      editProfileData: {},
      passwordData: { current_password: '', new_password: '', new_password_confirmation: '' },
      editAvatarModal: false,
      editAvatarData: {}
    }
  },
  computed: {
  },
  mounted: function () {
    this.checkSession()
  },
  methods: {
    checkSession () {
      this.$store.dispatch('checkSession').then(res => {
        this.profileData.name = res[0].name
        this.profileData.email = res[0].email
        this.profileData.avatar = res[0].avatar ? res[0].avatar : this.profileData.avatar
        this.editProfileData.name = res[0].name
        this.editProfileData.email = res[0].email
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
      })
    },
    updateAdminProfile () {
      if (this.editProfileData.name && this.editProfileData.email && /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/.test(this.editProfileData.email)) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateAdminProfile', this.$_.clone(this.editProfileData)).then(res => {
          this.profileData.name = this.editProfileData.name
          this.profileData.email = this.editProfileData.email
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else if (this.editProfileData.email && !/^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/.test(this.editProfileData.email)) {
        this.$swal('Warning', this.$t('email_format_is_incorrect'), 'warning')
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    updateAdminPassword () {
      if (this.passwordData.current_password && this.passwordData.new_password && this.passwordData.new_password_confirmation) {
        const loader = this.$loading.show()
        this.$store.dispatch('updateAdminPassword', this.$_.clone(this.passwordData)).then(res => {
          this.passwordData = { current_password: '', new_password: '', new_password_confirmation: '' }
          this.$swal('Success', this.$t('updated_successfully'), 'success')
          loader.hide()
        }).catch(e => {
          this.$swal('Error', e.message ? e.message : e, 'error')
          loader.hide()
        })
      } else {
        this.$swal('Warning', this.$t('required_fields_cannot_be_empty'), 'warning')
      }
    },
    editAvatar () {
      this.editAvatarData.avatar = this.profileData.avatar
      for (let i in this.avatarOptions) this.avatarOptions[i].active = false
      this.avatarOptions[this.$_.findIndex(this.avatarOptions, (option) => { return option.avatar === this.editAvatarData.avatar })].active = true
      this.editAvatarModal = true
    },
    avatarActive (idx) {
      this.editAvatarData.avatar = this.avatarOptions[idx].avatar
      for (let i in this.avatarOptions) this.avatarOptions[i].active = false
      this.avatarOptions[idx].active = true
    },
    updateAdminAvatar () {
      const loader = this.$loading.show()
      this.$store.dispatch('updateAdminAvatar', this.editAvatarData).then(res => {
        this.profileData.avatar = this.editAvatarData.avatar
        this.$store.commit('SET_AVATAR', this.profileData.avatar)
        this.editAvatarModal = false
        this.$swal('Success', this.$t('updated_successfully'), 'success')
        loader.hide()
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        loader.hide()
      })
    }
  }
}
</script>
